import request from "@/utils/request";

export function getQuestionTemplate(companyId, moduleId) {
  return request({
    url: "/app-api/company/question-template/getQuestionTemplate",
    method: "get",
    params: {
      id: companyId,
      moduleId: moduleId
    }
  });
}

