import { createStore } from "vuex";
import axios from "axios";

const store = createStore({
  state() {
    return {
      userInfo: null,
      currentCompanyId: null
    };
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_COMPANY_ID(state, companyId) {
      state.currentCompanyId = companyId;
    },
    CLEAR_INFO(state) {
      state.userInfo = null;
      state.currentCompanyId = null;
    }
  },
  actions: {
    async refreshToken({ commit, state }) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_API}/admin-app/system/auth/refresh-token`,
          {
            refreshToken: state.userInfo.data.refreshToken,
          }
        );
        const data = response.data;
        console.log("刷新Token成功:", data);
        commit("SET_USER_INFO", {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          expiresTime: data.expiresTime,
          userId: data.userId,
        });

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.accessToken}`;
      } catch (error) {
        console.error("刷新Token失败:", error);
      }
    },

    async setCompanyId({ commit }, companyId) {
      commit('SET_COMPANY_ID', companyId);
    },

    logout({ commit }) {
      commit('CLEAR_INFO');
      // 清除全局 axios 的 Authorization 头
      delete axios.defaults.headers.common["Authorization"];
      // 你还可以在这里清除 localStorage 或 sessionStorage 中的其他信息
      localStorage.removeItem('userInfo');
    }
  },
});

export default store;
