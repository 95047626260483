<template>
  <div class="error-page">
    <div class="error-content">
      <div class="error-code">404</div>
      <div class="error-desc">抱歉，你访问的页面不存在！</div>
      <div class="error-handle">
        <router-link to="/">
          <el-button type="primary">返回首页</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorPage",
};
</script>
<style>
.error-page {
  width: 100%;
  height: 100vh;
  background: url(@/assets/img/404.png) no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-content {
  text-align: center;
}
.error-code{
  font-size: 50px;
}
.error-desc{
  margin-top: 10px;
  margin-bottom: 20px;
  color: #606266;
}
</style>