import request from "@/utils/request";
// 提问接口
export function ask(data) {
  return request({
    url: "/admin-api/model/chat/ask",
    method: "get",
    params: data,
  });
}

// 获取相关问题
export function getNextQuestion(conversationId) {
  return request({
    url: "/app-api/model/chat/getNextQuestion?conversationId=" + conversationId,
    method: "get"
  });
}

// 流程：处理用户任务
export function doUserTask(data) {
  request({
    url: "/admin-api/model/flow/doTask",
    method: "post",
    params: data
  });
}