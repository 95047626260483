<template>
  <div class="head-top">
    <div class="w94">
      <div class="head-top-left">
        <img src="@/assets/img/jqr.png" alt="" width="30" />
        <p class="title">AI智能客服</p>
      </div>
      <div class="f">
        <div class="service-right" @click="handleClick">转人工</div>
        <i class="iconfont icon-xinxi" @click="toggleDialog"></i>
        <i class="iconfont icon-tuichudenglu" @click="logout"></i>
      </div>
    </div>
  </div>
  <!-- 公司信息模态框 -->
  <el-dialog
    v-model="dialogVisible"
    title="公司基本信息"
    width="700"
    style="border-radius: 20px"
  >
    <div class="companyInfo">
      <div>公司名称：{{ companyInfo.companyName || "暂无" }}</div>
      <div>公司描述：{{ companyInfo.companyDescription || "暂无" }}</div>
      <div>经营产业：{{ companyInfo.businessScope || "暂无" }}</div>
      <div>公司地址：{{ companyInfo.address || "暂无" }}</div>
      <div>公司邮箱：{{ companyInfo.email || "暂无" }}</div>
      <div>公司官网：{{ companyInfo.websiteUrl || "暂无" }}</div>
      <div>公司电话：{{ companyInfo.phoneNumber || "暂无" }}</div>
    </div>
  </el-dialog>
</template>
<script setup>
import { ref, onMounted, defineProps, watchEffect } from "vue";
// import { getCompanyInfo } from "@/api/companyApi";
import { useRoute, useRouter } from "vue-router";

const props = defineProps({
  isVisible: Boolean,
  companyInfo: {
    type: Object,
    required: true
  }
});

const route = useRoute();
const router = useRouter();
const dialogVisible = ref(false);
// const companyInfo = ref({});
const companyId = ref("");

// const fetchCompanyInfo = async () => {
//   try {
//     const response = await getCompanyInfo(companyId.value);
//     companyInfo.value = response.data.data;
//   } catch (error) {
//     console.error("请求数据失败:", error);
//   }
// };

const toggleDialog = () => {
  dialogVisible.value = !dialogVisible.value;
};

const logout = () => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("isAuthenticated");
  router.push("/");
};

onMounted(() => {
  companyId.value = route.query.companyId;
  // fetchCompanyInfo();
});

watchEffect(() => {
  dialogVisible.value = props.isVisible;
});
</script>
<style lang="scss" scoped>
</style>