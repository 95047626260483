<template>
  <div class="chat-footer" :style="{ borderColor: color }">
    <div class="upload-name1" v-if="imageName">
      {{ imageName }}
      <i class="iconfont icon-shanchu" @click="$emit('deleteImage')"></i>
    </div>
    <div style="display: flex; align-items: center">
      <textarea
        name="chatInput"
        v-model="localUserInput"
        :placeholder="inputPlaceholder"
        :disabled="type !== 1"
        @keydown="$emit('handleKeyDown')"
        rows="1"
      ></textarea>
      <div
        class="send-btn dp"
        @click="handleSendMessage"
        :disabled="isSending"
        :style="{ background: color }"
      >
        <i class="iconfont icon-fasong-" title="发送消息"></i>
      </div>
      <div
        class="send-btn dp"
        @click="$emit('toggleBtn')"
        style="margin-right: 10px"
      >
        <i class="iconfont icon-jia" title="更多"></i>
      </div>
    </div>
    <div class="item-file" v-show="showBtn">
      <div class="upload-img">
        <div @click="triggerFileUpload">
          <div class="cTxt">
            <i
              class="iconfont icon-tupian"
              title="上传图片"
              :style="{ color: color }"
            ></i>
            <p>上传图片</p>
          </div>
          <input
            type="file"
            ref="fileInput"
            @change="uploadFile($event)"
            style="display: none"
          />
        </div>
        <div class="cTxt">
          <i
            class="iconfont"
            :class="isImageGenMode ? 'icon-wenzi' : 'icon-icon-'"
            @click="toggleImageGenMode"
          ></i>
          <p>{{ isImageGenMode ? "纯文本模式" : "图生文模式" }}</p>
        </div>

        <div class="cTxt">
          <i
            class="iconfont"
            :class="type === 1 ? 'icon-kefu' : 'icon-wenjuan'"
            @click="rotateIcon"
          ></i>
          <p>{{ type === 1 ? "AI客服" : "调查问卷" }}</p>
        </div>
      </div>
    </div>
    <div class="f-l">
      <div class="service" @click="handleClick">
        <i class="iconfont icon-kefu"></i><span>人工客服</span>
      </div>
      <div class="bottom-btn">
        <div
          class="upload-name"
          v-if="imageName"
          @mouseenter="setHovering(true)"
          @mouseleave="setHovering(false)"
        >
          >
          {{ imageName }}
          <i
            class="iconfont"
            :class="isHovering ? 'icon-shanchu' : 'icon-zhengque'"
            @click="deleteImage"
            v-if="isHovering"
          ></i>
        </div>
        <div @click="triggerFileUpload">
          <i
            class="iconfont icon-tupian"
            title="上传图片"
            :style="{ color: color }"
          ></i>
          <input
            type="file"
            ref="fileInput"
            @change="uploadFile($event)"
            style="display: none"
          />
        </div>
        <div class="upload-img">
          <i
            class="iconfont"
            :style="{ color: color }"
            :class="isImageGenMode ? 'icon-wenzi' : 'icon-icon-'"
            title="文字生成图片"
            @click="toggleImageGenMode"
          ></i>
        </div>
        <div
          class="send-btn"
          @click="handleSendMessage"
          :disabled="isSending"
          :style="{ background: color }"
        >
          <i class="iconfont icon-fasong-" title="发送消息"></i>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref, watch, defineProps, defineEmits } from "vue";

const props = defineProps({
  color: String,
  imageName: String,
  userInput: {
    type: String,
    default: "",
  },
  isSending: Boolean,
  showBtn: Boolean,
  type: Number,
  inputPlaceholder: String,
  isImageGenMode: Boolean,
  isHovering: Boolean,
});

const emit = defineEmits([
  "deleteImage",
  "update:userInput",
  "sendMessage",
  "toggleBtn",
  "uploadFile",
  "toggleImageGenMode",
  "rotateIcon",
  "handleClick",
  "updateHovering",
  "sendMessage"
]);

const localUserInput = ref(props.userInput);
watch(
  () => props.userInput,
  (newVal) => (localUserInput.value = newVal)
);
watch(localUserInput, (newValue) => emit("update:userInput", newValue));

const fileInput = ref(null);
const triggerFileUpload = () => fileInput.value?.click();
const handleClick = () => emit("handleClick");
const deleteImage = () => emit("deleteImage");
const uploadFile = (event) => {
  const files = event.target.files;
  if (!files.length) return;
  emit("uploadFile", files[0]);
};

// 发射updateHovering更新父组件的isHovering状态
const setHovering = (hovering) => {
  emit("updateHovering", hovering);
};
//传递发送消息事件
const handleSendMessage = () => {
  emit("sendMessage", localUserInput.value);
  localUserInput.value = '';
};

</script>

<style scoped>
</style>