import { createRouter, createWebHistory } from "vue-router";
import IndexPage from "@/view/index/index.vue";
import LoginPage from "@/view/login/login.vue";
import NotFoundPage from "@/view/NotFoundPage/NotFoundPage.vue";

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/index",
    name: "IndexPage",
    component: IndexPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const companyIdQuery = to.query.companyId;
  if(companyIdQuery) {
    console.log('找到ID:' + companyIdQuery);
    localStorage.setItem('companyId', companyIdQuery);
  }
  if (to.name !== "LoginPage" && !isAuthenticated) {
    // 如果用户未认证并试图访问非登录页面，重定向到登录页面
    next({ name: "LoginPage" });
  } else {
    // 用户已认证或访问登录页面
    // 检查访问的是否为首页且URL中没有companyId
    if (to.name === "IndexPage" && !to.query.companyId) {
      if (companyIdQuery) {
        // 如果localStorage中有companyId，则重定向到首页并附带companyId
        next({ name: "IndexPage", query: { companyId: companyIdQuery } });
      } else {
        // 如果没有存储的companyId，可能需要处理这种异常情况，例如重新登录或错误处理
        next({ name: "LoginPage", query: { companyId: '1753588042175647745' } });
      }
    } else {
      // 对于其他情况，继续正常的路由导航
      next();
    }
  }
});

export default router;
