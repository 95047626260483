<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h2>请选择模块</h2>
      <ul>
        <li v-for="item in items" :key="item.id" @click="selectItem(item)">
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
  <div v-if="selectedItem">
    <h2>Selected Item</h2>
    <p>ID: {{ selectedItem.id }}</p>
    <p>Name: {{ selectedItem.name }}</p>
    <p>Description: {{ selectedItem.description }}</p>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectedItem: null
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    selectItem(item) {
      this.selectedItem = item;
      this.$emit('select', item);
      this.closeModal();
    }
  }
};
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
  padding: 10px;
  border: 1px solid #ddd;
  margin: 5px 0;
  cursor: pointer;
}

li:hover {
  background-color: #f1f1f1;
}
</style>
