import axios from "axios";
import store from "../store/store";

const baseURL = process.env.VUE_APP_BASE_API || "http://127.0.0.1:48080";

const service = axios.create({
  baseURL: baseURL,
  timeout: 5000,
});

// 请求拦截器
service.interceptors.request.use( 
  (config) => {
    const userInfo = store.state.userInfo;
    if (userInfo && userInfo.data && userInfo.data.accessToken) {
      console.log("获取到的token:", userInfo.data.accessToken);
      config.headers["Authorization"] = `Bearer ${userInfo.data.accessToken}`;
    } else {
      console.log("未获取到token");
    }

    return config;
  },
  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    console.log('Response received:', response); // 增加日志
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      console.error('Request timeout:', error.config);
      // 处理超时逻辑
      return Promise.reject(new Error('Request timed out, please try again later.'));
    }
    if (error.response && error.response.status === 500 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // 尝试刷新令牌
        await store.dispatch("refreshToken");
        console.log("刷新token成功");
        // 如果刷新成功，重试原始请求
        return service(originalRequest);
      } catch (refreshError) {
        // 如果刷新失败，相应处理，例如重定向到登录页
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default service;
