import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import axios from "axios";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/assets/iconfont/iconfont.css";
import "@/assets/iconfont2/iconfont.css";
const app = createApp(App);

const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));

if (storedUserInfo) {
  store.commit("SET_USER_INFO", storedUserInfo);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${storedUserInfo.accessToken}`;
}

app.use(ElementPlus);
app.use(store);
app.use(router);

app.mount("#app");
