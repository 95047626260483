import request from "@/utils/request";

// 用户注册
export function registerApi(param) {
  return request({
    url: "/app-api/system/ordinary/user/register",
    method: "post",
    data: param,
  });
}
//用户登录
// export function login(param) {
//   return request({
//     url: "/app-api/system/ordinary/user/login",
//     method: "post",
//     data: param,
//   });
// }
export function login(param) {
  return request({
    url: "/app-api/system/ordinary/user/loginReturnToken",
    method: "post",
    data: param,
  });
}
