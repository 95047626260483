<template>
  <!-- 自定义颜色 -->
  <div class="custom-color">
    <el-color-picker v-model="color" @change="colorChange" size="medium"></el-color-picker>
  </div>
</template>
<script setup>
import { ref,defineEmits } from "vue";
const color = ref("#6d5ffd"); //默认颜色
const emit = defineEmits(["colorChange"]); //定义emit函数发送事件

const colorChange = (val) => {
  emit("colorChange", val); //发送事件，将颜色传递给父组件
};
</script>
<style lang="scss" scoped>
</style>