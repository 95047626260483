<template>
  <div class="roboPage">
    <!-- 自定义颜色组件 -->
    <CustomColor @colorChange="handleColorChange" />
    <!-- 顶部导航 -->
    <TopNavigation :isVisible="dialogVisible" :companyInfo="companyInfo" />
    <div class="container">
      <!-- 左侧导航 -->
      <LeftSide :color="color" :historyItems="historyItems" :anyMessage="messages.length" :is-process="enableProcess"
        @updateVisibility="handleVisibilityChange" @toggle-dialog="handleDialogToggle" @upMessages="handleMessage"
        @upIsChatContentVisible="hanleIsChatContentVisible" />
      <!-- 对话框 -->
      <div class="rightDialog">
        <ChatContent :color="color" :isChatContentVisible="isChatContentVisible" :companyInfo="companyInfo"
          :isSending="isSending" @getModuleNames="handleModuleNamesChange" @updateMessages="handleMessageUpdate"
          @updateIsSending="handleSendingUpdate" @updataType="handleTypeUpdate" @upSurveyId="handleSurveyIdChange"
          @upHistoryItems="handleHistoryItems" @getModuleData="getModuleData"
          @upIsChatContentVisible="hanleIsChatContentVisible" />
        <!-- 聊天记录区域 -->
        <div class="chat-history">
          <div class="showTime">
            {{ greetingMessage }},AI智能客服&nbsp;为您服务
          </div>
          <div class="wrap">
            <div v-if="type === 1">
              <div class="message d" style="margin-top: 10px">
                <div style="display: flex">
                  <img src="@/assets/img/jqr.png" alt="Bot Icon" width="30" height="30" style="margin-right: 10px" />
                  <div class="message-content bot-message" style="font-size: 14px">
                    {{ companyInfo?.welcomeMessage || "欢迎使用AI智能客服" }}
                  </div>
                </div>
              </div>
              <div class="message d" style="margin-top: 10px" id="uniqueIdentifier">
                <div style="display: flex">
                  <img src="@/assets/img/jqr.png" alt="Bot Icon" width="30" height="30" style="margin-right: 10px" />
                  <div class="message-content bot-message" style="font-size: 16px">
                    <img src="@/assets/img/robotCommon.png" alt="" />
                    <div class="question-template" v-show="isShow && type === 1">
                      <el-row :gutter="24">
                        <el-col :span="24"
                          v-for="(item, index) in contentList.slice(pageParams.pageNo, pageParams.pageSize)" :key="index">
                          <div class="question-content bg-purple" @click="submitQuestion(item)">
                            {{ item.content }}
                          </div>
                        </el-col>
                      </el-row>

                      <div v-if="contentList.length > 6" style="text-align: left; color: #6d5ffd; cursor: pointer;">
                        <img src="@/assets/icons/lastPage.png" width="25" style="margin: 0 4px; display: inline;"
                          @click="lastPageNoParams()">
                        <img src="@/assets/icons/nextPage.png" width="25" style="margin: 0 4px; display: inline;"
                          @click="nextPageNoParams()">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="message d" style="margin-top: 10px" v-if="type === 2">
              <div style="display: flex">
                <img src="@/assets/img/jqr.png" alt="Bot Icon" width="30" height="30" style="margin-right: 10px" />
                <div class="message-content bot-message" style="font-size: 14px">
                  <img src="@/assets/img/robotCommon1.png" alt="" />
                  <!-- 提问模版 -->
                  <div class="question-template">
                    <el-row :gutter="24">
                      <el-col :span="24" v-for="(item, index) in SurveysList" :key="index">
                        <div class="question-content bg-purple">
                          <div @click="submitQuestion(item)">
                            {{ item.surveyName }}
                          </div>
                          <p style="
                              padding-top: 2px;
                              color: #6d5ffd;
                              font-size: 14px;
                            " @click="resetWj(item)">
                            重置问卷
                          </p>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
            <div class="message" v-for="(msg, index) in messages" :key="index">
              <img v-if="msg.sender === 'bot' ||
                msg.sender === 'tip' ||
                msg.sender === 'score'
                " src="@/assets/img/jqr.png" alt="Bot Icon" width="30" height="30" style="margin-right: 10px" />
              <i v-else class="iconfont" :class="{
                'icon-yonghu': msg.sender === 'user',
              }"></i>

              <div class="message-content" :class="{
                'user-message': msg.sender === 'user',
                'bot-message': msg.sender === 'bot',
              }" v-if="type === 1">
                <div v-if="msg.sender === 'user'" v-html="msg.content"></div>
                <div v-if="msg.sender === 'bot'">
                  <!-- 对话内容 -->
                  <div v-html="msg.content"></div>
                  <div v-if="msg.type === 'question-template'"> <!-- 新增 -->
                    <div class="message d" style="margin-top: 10px"> <!-- 新增 -->
                      <div style="display: flex"> <!-- 新增 -->
                        <div class="message-content bot-message" style="font-size: 16px">
                          <img src="@/assets/img/robotCommon.png" alt="" />
                          <div class="question-template" v-show="isShow && type === 1">
                            <el-row :gutter="24">
                              <el-col :span="24" v-for="(item, index) in msg.contentList.slice(msg.pageNo, msg.pageSize)"
                                :key="index">
                                <div class="question-content bg-purple" @click="submitQuestion(item)">
                                  {{ item.content }}
                                </div>
                              </el-col>
                            </el-row>
                            <div v-if="msg.contentList.length > 6"
                              style="text-align: left; color: #6d5ffd; cursor: pointer;">
                              <img src="@/assets/icons/lastPage.png" width="25" style="margin: 0 4px; display: inline;"
                                @click="lastPage(index)">
                              <img src="@/assets/icons/nextPage.png" width="25" style="margin: 0 4px; display: inline;"
                                @click="nextPage(index)">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> <!-- 新增 -->
                  </div> <!-- 新增 -->
                  <!-- 相关链接 -->
                  <div v-if="msg.link && msg.link.length > 0" class="link-section">
                    <h3 class="link-title">相关链接：</h3>
                    <div class="link-container">
                      <a :href="item" target="_blank" v-for="(item, index) in msg.link" :key="index">{{ item }}</a>
                    </div>
                  </div>
                  <!-- 图片展示区域 -->
                  <div v-if="msg.images && msg.images.length > 0" class="image-section">
                    <h3 class="section-title">相关图片</h3>
                    <div class="image-container">
                      <img :src="image" alt="Message Image" v-for="(image, index) in msg.images" :key="index"
                        class="message-image" @click="showImage(image)" />
                    </div>
                  </div>
                  <div v-if="msg.image" class="image-section">
                    <div class="image-container">
                      <img :src="msg.image" alt="Message Image" class="message-image" @click="showImage(msg.image)" />
                    </div>
                  </div>
                  <div v-if="recommendedQuestions.length &&
                    messages.length - 1 === index
                    " class="image-section">
                    <h3 class="section-title">你可以继续问我</h3>
                    <div class="question-container">
                      <button v-for="(item, index) in recommendedQuestions" :key="index"
                        @click="handleQuestionClick(item)">
                        {{ item }}
                      </button>
                    </div>
                  </div>
                  <!-- 图片显示拟态框 -->
                  <div v-if="showModal" class="modal" @click="closeModal">
                    <div class="modal-content" @click.stop>
                      <span class="close" @click="closeModal">&times;</span>
                      <img :src="selectedImage" alt="Expanded Image" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="message-content" :class="{
                'user-message': msg.sender === 'user',
                'bot-message': msg.sender === 'bot',
                'tip-message': msg.sender === 'tip',
                'score-message': msg.sender === 'score',
              }" v-else>
                <div v-if="msg.sender === 'user'" v-html="msg.content"></div>
                <div v-if="msg.sender === 'score'">
                  {{ scoresNumber }}
                </div>
                <div v-if="msg.sender === 'tip'">
                  《{{ msg.content }}》该模块已完成
                </div>
                <div v-if="msg.sender === 'bot'">
                  <div v-if="msg.questionContent">
                    <div class="current">
                      <p>
                        {{
                          msg.questionContent.questionContent
                        }}&nbsp;&nbsp;【剩余问题:{{
  msg.questionContent.remainingIssues
}}】
                      </p>
                      <div v-if="msg.questionContent.answers &&
                          msg.questionContent.answers.length
                          ">
                        <el-radio-group v-model="selectedAnswer" @change="onAnswerSelected">
                          <el-radio v-for="(answer, index) in msg.questionContent
                            .answers" :key="index" :label="answer.id" :disabled="msg.questionContent.isAnswered"
                            :checked="answer.id === msg.questionContent.selectedAnswerId
                              ">
                            {{ answer.content }}
                          </el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(item, index) in msg.content" :key="index">
                      <div class="current" @click="getQuestion(item)">
                        {{ item.moduleName }}&nbsp;&nbsp;<span style="color: #6d5ffd">未完成:{{ item.unfinishedQuantity
                        }}</span>&nbsp;&nbsp;<span style="color: #6d5ffd">当前分数:{{ item.score || 0 }}分</span>
                      </div>
                    </div>
                    <div class="current" @click="getScores()">查看总分</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 发送消息 -->
        <ChatFooter :color="color" :imageName="imageName" :userInput="userInput" :isSending="isSending" :showBtn="showBtn"
          :type="type" :inputPlaceholder="inputPlaceholder" :isImageGenMode="isImageGenMode" :isHovering="isHovering"
          @deleteImage="deleteImage" @handleKeyDown="handleKeyDown" @sendMessage="sendMessage" @toggleBtn="toggleBtn"
          @uploadFile="uploadFile" @toggleImageGenMode="toggleImageGenMode" @rotateIcon="rotateIcon"
          @handleClick="handleClickParent" @updateHovering="(value) => (isHovering = value)" />
        <!-- 浮动按钮 -->
        <input type="button" width="30" height="30" value="切换模块" @click="toggleModal" class="floating-button" title="发送消息"
          style="position: absolute; bottom: 200px; right: 20px; z-index: 1000;"
          v-if="(isMobileScreen && enableModules == '1')" />
      </div>
    </div>
  </div>
  <ModalComponent v-if="isModalVisible" :items="items" :showModal="isModalVisible" @close="toggleModal"
    @select="handleSelect" />
</template>

<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  nextTick,
  watch,
  computed,
  provide,
  reactive,
} from "vue";
import { defineComponent } from "vue";
import { getQuestionTemplate } from "@/api/templateAip";
import { getCompanyInfo } from "@/api/companyApi";
import { upload } from "@/api/uploadApi";
import { imageGen, checkImageGeneration } from "@/api/imageGenApi";
import { getNextQuestion, doUserTask } from "@/api/askApi";
import { getRecords, genRecords } from "@/api/recordsApi";
import {
  getSurveys,
  getModulesBySurveyId,
  pushQuestion,
  answer,
  getScore,
  deleteUserAnswer,
} from "@/api/surveysApi";
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import "@/assets/css/indexPage.css";
import { ElMessage } from "element-plus";
import LeftSide from "@/components/leftSide.vue";
import CustomColor from "@/components/customColor.vue";
import TopNavigation from "@/components/topNavigation.vue";
import ChatContent from "@/components/chatContent.vue";
import ChatFooter from "@/components/ChatFooter.vue";
import ModalComponent from "@/components/moduleList.vue";
import { getModule } from "@/api/moduleApi";
const color = ref("#6d5ffd"); // 初始化颜色
const greetingMessage = ref(""); // 初始化欢迎语时间
const chrentMessage = ref([]); // 初始化聊天记录
const showBtn = ref(false); // 初始化是否显示按钮
const contentList = ref([]);
const companyInfo = ref({});
const companyName = ref("");
const messages = ref([]);
const userInput = ref("");
const isMobileScreen = ref(false);
const pageParams = ref({ pageSize: 6, pageNo: 0 });
const SurveysList = ref([]);
const modulesList = ref([]);
const moduleNames = ref([]);
const selectedAnswer = ref("");
const currentModuleId = ref("");
const scoresNumber = ref(0);
const surveyId = ref("");
const type = ref(1);
const isHovering = ref(false);
let eventSource = null;
const companyId = ref("");
const dialogVisible = ref(false);
const isChatContentVisible = ref(false);
const isShow = ref(false);
const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
const userId = ref(userInfo.data.userId);
const route = useRoute(); // 获取路由实例
const isSending = ref(false);
const Istrue = ref(false);
const loading = ref(true);
const isRotating = ref(false);
const store = useStore();
const currentConversationId = ref(null);
const isImageGenMode = ref(false);
const recommendedQuestions = ref([]);
const accessToken = ref(store.state.userInfo.data.accessToken);
const showModal = ref(false);
const selectedImage = ref(null);
const pollingInterval = ref(null);
const timeoutId = ref(null);
const isPolling = ref(false);
const doTaskId = ref(null);
const sharedState = reactive({
  refresh: false,
});
const moduleData = ref("");
const getModuleData = (newModuleData) => {
  console.log("newModuleData:" + newModuleData);
  moduleData.value = newModuleData;
};
const enableModules = ref('0');
const enableProcess = ref(false);
const contentData = ref([]);
const isModalVisible = ref(false);
const items = ref([]);
const selectedItem = ref(null);
const toggleModal = () => {
  isModalVisible.value = !isModalVisible.value;
};
const fetchModuleData = async () => {
  try {
    if (enableModules.value == '1') {
      const response = await getModule({ companyId: companyId.value });
      items.value = response.data.data.list;
      if (items.value.length > 0) {
        moduleData.value = items.value[0].moduleId
        // fetchContentList();
        contentList.value = getFetchContentList(moduleData.value)
      }
    } else {
      contentList.value = contentData.value
    }
  } catch (error) {
    console.error("Error fetching module data:", error);
  }
};
const handleSelect = (item) => {
  selectedItem.value = item;
  moduleData.value = selectedItem.value.moduleId;
  addMessage(item.name, getFetchContentList(moduleData.value))
  // fetchContentList();
};

// 分页跳转
const lastPage = (index) => {
  const pageNo = messages.value[index].pageNo;
  const pageSize = messages.value[index].pageSize;
  if (pageNo - 6 >= 0) {
    messages.value[index].pageNo = pageNo - 6;
    messages.value[index].pageSize = pageSize - 6;
  }
};

const nextPage = (index) => {
  const pageNo = messages.value[index].pageNo;
  const pageSize = messages.value[index].pageSize;
  const total = messages.value[index].contentList.length;
  if (pageSize <= total) {
    messages.value[index].pageNo = pageNo + 6;
    messages.value[index].pageSize = pageSize + 6;
  }
};

const lastPageNoParams = () => {
  if (pageParams.value.pageNo - 6 >= 0) {
    pageParams.value.pageSize -= 6;
    pageParams.value.pageNo -= 6;
  }
};

const nextPageNoParams = () => {
  if (pageParams.value.pageSize <= contentList.value.length) {
    pageParams.value.pageSize += 6;
    pageParams.value.pageNo += 6;
  }
};

const addMessage = (value, contentList) => {
  const message = {
    sender: "bot",
    type: "prompt",
    content: "您已切换为：" + value,
  };
  const newMessage = {
    sender: "bot",
    type: "question-template",
    contentList: contentList,
    pageSize: 6,
    pageNo: 0
  };
  const messageDiv = document.querySelector('#uniqueIdentifier');
  if (messageDiv) {
    messageDiv.remove();
  }
  messages.value.push(message);
  messages.value.push(newMessage);
  scrollToBottom();
};
const getFetchContentList = (value) => {
  // 提取和 value 匹配的数据
  contentList.value = contentData.value.filter(item => item.moduleId == value);
  return contentData.value.filter(item => item.moduleId == value);
};
defineComponent({
  name: "IndexPage",
});
// toggle button切换显示
const toggleBtn = () => {
  showBtn.value = !showBtn.value;
};
//更新父组件的颜色
const handleColorChange = (colorValue) => {
  color.value = colorValue;
};
//更新父组件的问卷分页
const handleModuleNamesChange = (moduleNames) => {
  moduleNames.value = moduleNames.map((item) => item.moduleName);
  messages.value.push({ sender: "bot", content: moduleNames });
};
//更新父组件禁用输入框
const handleSendingUpdate = (newSendingStatus) => {
  isSending.value = newSendingStatus;
};
//更新父组件获取子组件发送消息
const handleMessageUpdate = (newMessage) => {
  if (newMessage.sender === "user") {
    messages.value.push({
      sender: "user",
      content: newMessage.content?.[0]?.content,
    });
    chrentMessage.value = messages.value;
  } else if (newMessage.sender === "bot") {
    console.log("检查是否进来");
    console.log("bot message", newMessage.content);
  }
  console.log("用户发起了提问.....");
};
//更新父组件获取子组件发送消息
const handleMessage = (newMessage) => {
  messages.value = [];
  console.log("父组件获取子组件发送的消息：", newMessage);
  newMessage.forEach((msg) => {
    if (msg.sender === "user") {
      messages.value.push({
        sender: "user",
        content: msg.content,
      });
    } else if (msg.sender === "bot") {
      messages.value.push({
        sender: "bot",
        content: msg.content,
      });
    }
  });
};
//更新父组件显示状态
const hanleIsChatContentVisible = (newIsChatContentVisible) => {
  isChatContentVisible.value = newIsChatContentVisible;
};
//更新父组件类型
const handleTypeUpdate = (newType) => {
  type.value = newType;
};
const handleSurveyIdChange = (newSurveyId) => {
  surveyId.value = newSurveyId;
  console.log("surveyId", surveyId.value);
};
const handleHistoryItems = (newHistoryItems) => {
  historyItems.value = newHistoryItems;
};
//重置问卷
const resetWj = async (item) => {
  console.log("重置问卷:", item);
  try {
    const response = await deleteUserAnswer({
      surveyId: item.id,
      userId: userId.value,
    });
    if (response.data.code === 0) {
      messages.value = [];
      console.log("问卷重置成功", response);
      ElMessage.success("问卷重置成功");
      await getSurveys();
    }
  } catch (error) {
    console.error("问卷重置失败", error);
  }
};

// 计算属性，根据屏幕尺寸返回不同的 placeholder 文本
const inputPlaceholder = computed(() => {
  return isMobileScreen.value
    ? "说点什么吧..."
    : "在此输入您的问题，Enter 发送，Ctrl + Enter 换行";
});
// 屏幕尺寸改变时的处理函数
const handleResize = () => {
  isMobileScreen.value = window.matchMedia("(max-width: 768px)").matches;
};
const historyItems = ref([]);

//上传图片
const imageUrl = ref(null);
const imageName = ref(null);

const uploadFile = async (input) => {
  let file;
  if (input instanceof Event && input.target && input.target.files) {
    file = input.target.files[0];
  } else if (input instanceof File) {
    file = input; // 直接使用文件对象
  }

  if (!file) {
    console.log("没有选择文件");
    return;
  }

  console.log("触发文件上传:", file);

  nextTick(() => {
    imageName.value = file.name;
  });

  const formData = new FormData();
  formData.append("file", file);

  try {
    isSending.value = true;
    const response = await upload(formData);
    isSending.value = false;

    if (response.data.code === 0) {
      imageUrl.value = response.data.data;
      console.log("文件上传成功", imageUrl.value);
    } else {
      console.error("文件上传失败", response.data.message);
    }
  } catch (error) {
    isSending.value = false;
    console.error("上传过程中发生错误", error);
  }
};
const deleteImage = () => {
  imageName.value = null;
  imageUrl.value = null;
};

//文字生成图片
const toggleImageGenMode = () => {
  isImageGenMode.value = !isImageGenMode.value;
  if (isImageGenMode.value) {
    ElMessage.success("您已切换到文字生成图片模式");
  } else {
    ElMessage.success("您已切换回纯文本模式");
  }
};

// 进入聊天页面查询对话列表
const getChatList = async () => {
  const data = {
    userId: userId.value,
  };
  const res = await getRecords(data);
  if (res.data && Array.isArray(res.data.data)) {
    console.log("对话列表:", res.data.data);
    historyItems.value = res.data.data.slice(0, 30);
  } else {
    console.log("对话列表数据不可用或不是一个数组");
    historyItems.value = [];
  }

  if (res.code === 200) {
    messages.value = res.data;
  }
};

// 获取问卷列表
const getSurveysList = async () => {
  const data = {
    creatorCompanyId: companyId.value,
    pageNo: 1,
    pageSize: 10,
  };
  try {
    const response = await getSurveys(data);
    SurveysList.value = response.data.data.list;
    console.log(SurveysList.value);
  } catch (e) {
    console.error("请求数据失败:", e);
  }
};

const fetchRecommendedQuestions = async () => {
  try {
    const response = await getNextQuestion(currentConversationId.value);
    console.log(response);
    if (response.data.code === 0) {
      recommendedQuestions.value = response.data.data;
      scrollToBottom();
    }
  } catch (error) {
    console.error("获取推荐问题失败:", error);
  }
};

const handleQuestionClick = (question) => {
  messages.value.push({ sender: "user", content: question });
  openEventSourceConnection(question, currentConversationId.value);
};

const openEventSourceConnection = (question, conversationId) => {
  // 清空推荐问题
  recommendedQuestions.value = [];
  let sseUrl = `${process.env.VUE_APP_BASE_API}/admin-api/model/chat/ask`;
  let typingMessageIndex = messages.value.length;
  messages.value.push({ sender: "bot", content: "" });

  let isMessageNew = false;

  let requestBody = {
    'companyId': companyId.value,
    'userId': userId.value,
    'moduleId': moduleData.value,
    ...(conversationId ? { 'conversationId': conversationId } : {}),
    'question': encodeURIComponent(question || userInput.value)
  }

  const ctrl = new AbortController();

  fetchEventSource(sseUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken.value}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody),
    openWhenHidden: true,
    signal: ctrl.signal,
    onmessage(event) {
      const cleanData = JSON.parse(event.data);

      if (cleanData.type === `TEXT`) {
        if (isMessageNew === true) {
          typingMessageIndex += 1;
          messages.value.push({ sender: "bot", content: "" })
        }
        const updatedContent =
        messages.value[typingMessageIndex].content + cleanData.content;
        messages.value[typingMessageIndex] = {
          sender: "bot",
          content: updatedContent.replace("\n", "<br>"),
        };
        isMessageNew = cleanData.isFinish;
      } else if (cleanData.type === `LINK`) {
        messages.value[typingMessageIndex].link = cleanData.content;
      } else if (cleanData.type === "IMAGE") {
        messages.value[typingMessageIndex].images = cleanData.content;
      }
      messages.value = [...messages.value];
      scrollToBottom();
    },
    onerror(error) {
      isSending.value = false;
      ctrl.abort();
    },
    onclose() {
      isSending.value = false;
    }
  });
};

const typingMessageIndex = ref(messages.value.length);

const requestEventSource = () => {
  // 清空推荐问题
  recommendedQuestions.value = [];
  const sseUrl = `${process.env.VUE_APP_BASE_API}/admin-api/model/flow/runJob`;

  messages.value.push({ sender: "bot", content: "" });

  let isMessageNew = false;

  let requestBody = {
    'companyId': companyId.value,
    'sessionId': currentConversationId.value
  }

  const ctrl = new AbortController();

  fetchEventSource(sseUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken.value}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody),
    openWhenHidden: true,
    signal: ctrl.signal,
    onmessage(event) {
      const cleanData = JSON.parse(event.data);

      if (cleanData.type === `TEXT` || cleanData.type === `ERROR_INFO`) {
        if (isMessageNew === true) {
          typingMessageIndex.value += 1;
          messages.value.push({ sender: "bot", content: "" })
        }
        console.log('typingMessageIndex', typingMessageIndex.value);
        console.log('message', messages.value);
        isSending.value = cleanData.type !== `ERROR_INFO`;
        const updatedContent =
          messages.value[typingMessageIndex.value].content + cleanData.content;
          messages.value[typingMessageIndex.value] = {
          sender: "bot",
          content: updatedContent.replace("\n", "<br>"),
        };
        isMessageNew = cleanData.isFinish;
      } else if (cleanData.type === `LINK`) {
        messages.value[typingMessageIndex.value].link = cleanData.content;
      } else if (cleanData.type === "IMAGE") {
        messages.value[typingMessageIndex.value].images = cleanData.content;
      } else if (cleanData.type === "QUESTION") {
        isSending.value = false;
        doTaskId.value = cleanData.content;
      }
      messages.value = [...messages.value];
      scrollToBottom();
    },
    onerror(error) {
      ctrl.abort();
      isSending.value = false;
      throw new error(error)
    },
    onclose() {
      isSending.value = false;
    }
  });
};

const scrollToBottom = () => {
  nextTick(() => {
    const element = document.querySelector(".chat-history");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  });
};
//获取对话模版
const fetchContentList = async () => {
  try {
    const response = await getQuestionTemplate(companyId.value, moduleData.value);
    contentData.value = response.data.data;
  } catch (error) {
    console.error("请求数据失败:", error);
  }
};
//获取公司基本信息
const fetchCompanyInfo = async () => {
  try {
    const response = await getCompanyInfo(companyId.value);
    companyInfo.value = response.data.data;
    companyName.value = companyInfo.value.companyName;
    enableModules.value = companyInfo.value.enableModules;
    enableProcess.value = companyInfo.value.enableProcess;
    // 如果流程标识：enableProcess为true则需要立即执行开启流程接口
    if (enableProcess.value) {
      const response = await genRecords({ userId: userId.value, name: new Date().toLocaleString() });
      currentConversationId.value = response.data.data.id;
      isChatContentVisible.value = false;
      requestEventSource();
    } else {
      isChatContentVisible.value = true;
    }
  } catch (error) {
    console.error("请求数据失败:", error);
  }
};
//显示弹窗公司基本信息
const handleDialogToggle = (isVisible) => {
  dialogVisible.value = isVisible;
};
// 点击处理函数，执行跳转人工客服
const handleClickParent = () => {
  if (companyInfo.value.customerServiceLink) {
    window.location.href = companyInfo.value.customerServiceLink;
  } else {
    console.log("客服链接未获取到");
  }
};

// 轮询函数
function startPolling(taskId) {
  if (isPolling.value) return; // 如果已经在轮询，则不重复开始
  isPolling.value = true;
  pollingInterval.value = setInterval(async () => {
    try {
      const data = await checkImageGeneration({ taskId: taskId });
      if (data.data.code === 0 && data.data.data.taskStatus === "SUCCEEDED") {
        stopPolling();
        messages.value.push({ sender: "bot", image: data.data.data.url });
      }
    } catch (error) {
      console.error("查询出错:", error);
    }
  }, 2000); // 每2秒轮询一次
}

// 停止轮询函数
function stopPolling() {
  if (!isPolling.value) return; // 如果不在轮询，则不执行停止操作
  clearInterval(pollingInterval.value);
  clearTimeout(timeoutId.value);
  isPolling.value = false;
}

// 设置超时来停止轮询
function setTimeoutToStopPolling() {
  timeoutId.value = setTimeout(() => {
    if (isPolling.value) {
      stopPolling();
      console.log("查询超时，没有结果");
    }
  }, 20000); // 等待15秒后停止轮询
}

//发送消息逻辑
const sendMessage = async (inputMessage) => {
  console.log('isSending:', isSending.value);
  let message = inputMessage.trim().replace(/\n/g, "<br>"); // 使用参数而不是重新声明
  if (message && !isSending.value) {
    console.log("发送消息");
    isSending.value = true;
    isShow.value = true;
    loading.value = true;

    // 检查是否需要创建新对话
    if (historyItems.value.length < 0 || !currentConversationId.value) {
      try {
        const response = await genRecords({
          userId: userId.value,
          name: message,
        });

        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.id
        ) {
          currentConversationId.value = response.data.data.id;
          historyItems.value.push({
            id: response.data.data.id,
            name: response.data.data.name,
          });
        } else {
          console.error("新建对话失败，响应格式不正确:", response);
        }
        await getChatList();
      } catch (error) {
        isSending.value = false;
        return;
      }
    }

    if (enableProcess.value && doTaskId != null) {
      // 流程：处理用户的任务
      let requestBody = {
        'taskId': doTaskId.value,
        'sessionId': currentConversationId.value,
        'replyContent': encodeURIComponent(message)
      }
      messages.value.push({ sender: "user", content: message });
      doUserTask(requestBody);
      typingMessageIndex.value += 1;
    } else if (isImageGenMode.value) {
      // 文字生成图片模式的处理
      try {
        const response = await imageGen({ question: message });
        if (response.data.code === 0) {
          const taskId = response.data.data;
          if (taskId) {
            setTimeout(() => {
              startPolling(taskId);
              setTimeoutToStopPolling();
            }, 15000); // 等待图片生成完成
          }
        } else {
          return;
        }

        messages.value.push({
          sender: "user",
          content: message,
        });
        userInput.value = "";
        isChatContentVisible.value = false;
        scrollToBottom();
      } catch (error) {
        console.error("请求图片生成接口失败:", error);
      } finally {
        isSending.value = false;
        loading.value = false;
        userInput.value = "";
      }
    } else {
      if (imageUrl.value) {
        messages.value.push({
          sender: "user",
          content:
            message +
            '<br><br><img src="' +
            imageUrl.value +
            '" alt="上传的图片"/>',
        });
      } else {
        messages.value.push({ sender: "user", content: message });
        console.log("发送普通消息:" + messages.value.length);
      }
      openEventSourceConnection(message, currentConversationId.value);
      userInput.value = "";
      isChatContentVisible.value = false;
      imageName.value = null;
      imageUrl.value = null;
      scrollToBottom();
    }
  }
};

//根据问卷id获得问卷模块分页
const getModules = async (id) => {
  const data = {
    surveyId: id,
    pageNo: 1,
    pageSize: 20,
    userId: userId.value,
  };
  try {
    const response = await getModulesBySurveyId(data);
    modulesList.value = response.data.data.list;
    moduleNames.value = modulesList.value.map((item) => ({
      moduleName: item.moduleName,
      moduleId: item.id,
      unfinishedQuantity: item.unfinishedQuantity,
    }));
  } catch (e) {
    console.error("请求数据失败:", e);
  }
};
//获取问题
const getQuestion = async (item) => {
  if (item.unfinishedQuantity === 0) {
    messages.value.push({
      sender: "tip",
      content: item.moduleName,
    });
  } else {
    const moduleId = item.moduleId ? item.moduleId : item.id;
    const data = {
      moduleId: moduleId,
      userId: userId.value,
    };
    try {
      const response = await pushQuestion(data);
      const questionContentList = response.data.data;
      const questionWithIsAnswered = {
        ...questionContentList,
        isAnswered: false,
        selectedAnswerId: null,
      };
      currentModuleId.value = questionContentList.questionId;
      messages.value.push({
        sender: "user",
        content: questionContentList.questionContent,
      });
      messages.value.push({
        sender: "bot",
        questionContent: questionWithIsAnswered,
      });
      scrollToBottom();
    } catch (e) {
      console.error("请求数据失败:", e);
    }
  }
};
const submitQuestion = async (item) => {
  console.log("item1", item);
  if (type.value === 2) {
    console.log("item2", item);
    surveyId.value = item.id;
    getModules(surveyId.value);
    if (!isSending.value) {
      isSending.value = true;
      isShow.value = true;
      messages.value.push({ sender: "user", content: item.surveyName });
      messages.value.push({ sender: "bot", content: moduleNames });
      isChatContentVisible.value = false;
      nextTick(() => {
        scrollToBottom();
      });
    }
  } else {
    let message = item.content || item.surveyName;
    if (!isSending.value) {
      isSending.value = true;
      isShow.value = true;
      try {
        const name = message;
        const response = await genRecords({ userId: userId.value, name });
        currentConversationId.value = response.data.data.id;
        historyItems.value.push({
          id: response.data.data.id,
          name: response.data.data.name,
        });
        console.log("创建新对话成功:", response);
        await getChatList();
      } catch (error) {
        console.error("新建对话失败:", error);
        return;
      }
      messages.value.push({ sender: "user", content: message });
      openEventSourceConnection(message, currentConversationId.value);
      // requestEventSource(message, currentConversationId.value);
      userInput.value = "";
      isChatContentVisible.value = false;
      scrollToBottom();
    }
  }
};

// 清理 EventSource 连接
onUnmounted(() => {
  stopPolling(); // 停止图片生成的轮询
  window.removeEventListener("resize", handleResize); // 清理
  if (eventSource) {
    eventSource.close();
  }
});
onMounted(async () => {
  companyId.value = route.query.companyId;
  await fetchCompanyInfo();
  handleResize();
  window.addEventListener("resize", handleResize); // 监听屏幕尺寸变化
  if (localStorage.getItem("historyCleared") === "true") {
    ElMessage.success("已全部删除成功");
    localStorage.removeItem("historyCleared"); // 清除标志
  }
  if (type.value === 1) {
    await fetchContentList();
  } else {
    await getSurveysList();
  }
  await fetchModuleData();
});
//获取分数
const getScores = async () => {
  const data = {
    surveyId: surveyId.value,
    userId: userId.value,
  };
  try {
    const response = await getScore(data);
    scoresNumber.value = response.data.data;
    messages.value.push({ sender: "user", content: "查看总分分数" });
    messages.value.push({ sender: "score", content: scoresNumber.value });
    scrollToBottom();
  } catch (error) {
    console.log("请求数据错误：", error);
  }
};
const onAnswerSelected = async (selectedAnswerId) => {
  const currentQuestionIndex = messages.value.findIndex(
    (msg) =>
      msg.sender === "bot" &&
      msg.questionContent?.questionId === currentModuleId.value
  );
  if (currentQuestionIndex !== -1) {
    messages.value[currentQuestionIndex].questionContent.isAnswered = true;
    messages.value[currentQuestionIndex].questionContent.selectedAnswerId = selectedAnswerId;
  }
  scrollToBottom();
};
watch(isSending, (newVal, oldVal) => {
  console.log("isSending: old:", oldVal, "new:", newVal);
})
watch(selectedAnswer, async (newVal) => {
  if (newVal) {
    const data = {
      questionId: currentModuleId.value,
      result: newVal,
      userId: userId.value,
    };
    try {
      const response = await answer(data);
      Istrue.value = response.data.data;
    } catch (error) {
      console.log("请求数据错误：", error);
    }
    const datas = {
      surveyId: surveyId.value,
      pageNo: 1,
      pageSize: 20,
      userId: userId.value,
    };
    try {
      const response = await getModulesBySurveyId(datas);
      modulesList.value = response.data.data.list;
      moduleNames.value = modulesList.value.map((item) => ({
        moduleName: item.moduleName,
        moduleId: item.id,
        unfinishedQuantity: item.unfinishedQuantity,
        score: item.score,
      }));
    } catch (e) {
      console.error("请求数据失败:", e);
    }
    messages.value.push({ sender: "bot", content: moduleNames });
    scrollToBottom();
  }
});
const aiMessages = ref([]);
const surveyMessages = ref([]);
const rotateIcon = async () => {
  isRotating.value = true;
  if (type.value === 1) {
    surveyMessages.value = [...messages.value];
    messages.value = [...aiMessages.value];
    ElMessage.success("您已切换到调查问卷");
    await getSurveysList();
  } else {
    aiMessages.value = [...messages.value];
    messages.value = [...surveyMessages.value];
    isSending.value = false;
    ElMessage.success("您已切换回AI客服");
  }

  type.value = type.value === 1 ? 2 : 1;
};

// 定义处理按键事件的函数
// const handleKeyDown = (event) => {
//   if (event.ctrlKey && event.key === "Enter") {
//     userInput.value += "\n";
//   } else if (event.key === "Enter") {
//     event.preventDefault();
//     sendMessage();
//   }
// };
const showImage = (imageUrl) => {
  selectedImage.value = imageUrl;
  showModal.value = true;
};
const closeModal = () => {
  showModal.value = false;
};
// 监听页面可见性变化
const handleVisibilityChange = (newVisibility) => {
  isChatContentVisible.value = newVisibility
};
provide("scrollToBottom", scrollToBottom);
provide("openEventSourceConnection", openEventSourceConnection);
provide("sharedState", sharedState);
</script>

<style></style>