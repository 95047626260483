import request from "@/utils/request";
// 获取问卷分页
export function getSurveys(data) {
  return request({
    url: "/app-api/company/question/getSurveys",
    method: "get",
    params: data,
  });
}

// 根据问卷id获得问卷模块分页
export function getModulesBySurveyId(data) {
  return request({
    url: "/app-api/company/question/getModulesBySurveyId",
    method: "get",
    params: data,
  });
}

// 获取问题(每次只会获取一个问题)
export function pushQuestion(data) {
  return request({
    url: "/app-api/company/question/push",
    method: "get",
    params: data,
  });
}

// 回答问题
export function answer(data) {
  return request({
    url: "/app-api/company/question/answer",
    method: "post",
    data: data,
  });
}

// 获取分数
export function getScore(data) {
  return request({
    url: "/app-api/company/question/getScore",
    method: "get",
    params: data,
  });
}
// 重置问卷
export function deleteUserAnswer(data) {
  return request({
    url: "/app-api/company/question/deleteUserAnswer",
    method: "delete",
    params: data,
  });
}
