<template>
  <div class="chat-content" v-if="isChatContentVisible">
    <div class="chat-item">
      <div class="chat-item-left-content">
        <img src="@/assets/img/jqr.png" alt="" width="60" class="jqr" />
        <h1 class="chat-title">Hi&nbsp;{{ greetingMessage }}</h1>
        <p>{{ companyInfo?.welcomeMessage || "欢迎使用" }}</p>
      </div>
      <div class="hots-title">
        <div class="change-j">
          <div style="width: 200px" v-show="enableModules == '1' && type === 1">
            <el-select
              v-model="value"
              clearable
              placeholder="请选择"
              @change="handleSelectChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div @click="rotateIcon" style="cursor: pointer">
            <i
              class="iconfont icon-huanyihuan"
              :class="{ 'rotate-animation': isRotating }"
              @animationend="isRotating = false"
              :style="{ color: color }"
            ></i>
            <span v-if="type === 1">切换到调查问卷</span>
            <span v-else>智能客服机器人</span>
          </div>
        </div>
      </div>
      <el-row :gutter="24" v-if="type === 1">
        <el-col
          :span="8"
          v-for="(item, index) in contentList && contentList.slice(pageNo, pageSize)"
          :key="index"
        >
          <div class="grid-content bg-purple" @click="submitQuestion(item)">
            “{{ item.content }}”
            <div class="attempt" :style="{ color: color }">试一试</div>
          </div>
        </el-col>
      </el-row>
      <div style="text-align: right" v-if="type ===1 && contentList.length > 6">
        <img src="@/assets/icons/lastPage.png" width="25"  style="margin: 0 4px;" @click="lastPage()">
        <img src="@/assets/icons/nextPage.png" width="25"  style="margin: 0 4px;" @click="nextPage()">
      </div>
      <el-row :gutter="24" v-if="type === 2">
        <el-col :span="8" v-for="(item, index) in SurveysList" :key="index">
          <div class="grid-content bg-purple" @click="submitQuestion(item)">
            {{ item.surveyName }}
            <div
              class="attempt"
              :style="{ color: color }"
              @click.stop="resetWj(item)"
            >
              重置问卷
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  computed,
  onMounted,
  inject,
  nextTick,
  defineEmits,
  watch,
  onBeforeUpdate,
} from "vue";
import { useRoute } from "vue-router";
// import { getCompanyInfo } from "@/api/companyApi";
import { getQuestionTemplate } from "@/api/templateAip";
import { genRecords, getRecords } from "@/api/recordsApi";
import {
  getSurveys,
  getModulesBySurveyId,
  deleteUserAnswer,
} from "@/api/surveysApi";
import { ElMessage } from "element-plus";
import { getModule } from "@/api/moduleApi";
/* eslint-disable */
const props = defineProps({
  color: String,
  isSending: {
    type: Boolean,
    default: false,
  },
  isChatContentVisible: {
    type: Boolean,
    default: true,
  },
  companyInfo: {
    type: Object,
    required: true,
  },
});
watch(
  () => props.isChatContentVisible,
  (newValue) => {
    isChatContentVisible.value = newValue;
  }
);

watch(
  () => props.isSending,
  (newValue) => {
    console.log("isSending", newValue);
    isSending.value = newValue;
  }
);
const emit = defineEmits([
  "getModuleNames",
  "updateMessages",
  "updateIsSending",
  "updataType",
  "upSurveyId",
  "upIsChatContentVisible"
]);
const options = ref([]);
const pageNo = ref(0);
const pageSize = ref(6);
const value = ref(""); // 默认选择第一个选项
const isChatContentVisible = ref(props.isChatContentVisible); // 控制聊天模板是否显示
const companyId = ref(""); // 公司id
const type = ref(1); // 1为智能客服机器人，2为调查问卷
const isRotating = ref(false); // 控制图标旋转
const aiMessages = ref([]); // 智能客服机器人消息
const surveyMessages = ref([]); // 调查问卷消息
const messages = ref([]); // 聊天消息
const contentList = ref([]); // 智能客服机器模板列表
const SurveysList = ref([]); // 调查问卷列表
const isSending = ref(props.isSending); // 控制发送消息
const isShow = ref(false); // 控制聊天框显示
const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
const userId = ref(userInfo.data.userId); // 用户id
const currentConversationId = ref(null); // 当前会话id
const historyItems = ref([]); // 历史记录
const route = useRoute();
const openEventSourceConnection = inject("openEventSourceConnection");
const scrollToBottom = inject("scrollToBottom");
const sharedState = inject("sharedState");
const userInput = ref(""); // 用户输入
const surveyId = ref(""); // 问卷id
const moduleNames = ref([]); // 模块名称
const modulesList = ref([]); // 模块列表
const enableModules = ref("0");
const contentData = ref([]);
//获取当前时间
const hours = new Date().getHours();
const greetingMessage = computed(() => {
  if (hours < 12) {
    return "早上好";
  } else if (hours >= 12 && hours < 14) {
    return "中午好";
  } else if (hours >= 14 && hours < 18) {
    return "下午好";
  } else {
    return "晚上好";
  }
});
//获取公司信息
// const fetchCompanyInfo = async () => {
//   try {
//     const response = await getCompanyInfo(companyId.value);
//     companyInfo.value = response.data.data;
//     enableModules.value=response.data.data.enableModules;
//     console.log("输出状态：",enableModules.value)
//   } catch (error) {
//     console.error("请求数据失败:", error);
//   }
// };
//切换客服机器人、调查问卷
const rotateIcon = async () => {
  isRotating.value = true;
  if (type.value === 1) {
    surveyMessages.value = [...messages.value];
    messages.value = [...aiMessages.value];
    emit("updateIsSending", true);
    ElMessage.success("您已切换到调查问卷");
    await getSurveysList();
  } else {
    aiMessages.value = [...messages.value];
    messages.value = [...surveyMessages.value];
    emit("updateIsSending", false);
    ElMessage.success("您已切换回AI客服");
  }

  type.value = type.value === 1 ? 2 : 1;
  emit("updataType", type.value);
};
//获取对话模版
const fetchContentList = async () => {
  try {
    const response = await getQuestionTemplate(companyId.value, value.value);
    contentList.value = response.data.data;
    contentData.value = response.data.data;
  } catch (error) {
    console.error("请求数据失败:", error);
  }
};
//点击问题模板事件
const submitQuestion = async (item) => {
  emit("upIsChatContentVisible", false);
  if (type.value === 2) {
    surveyId.value = item.id;
    emit("upSurveyId", surveyId.value);
    getModules(surveyId.value);
    if (!isSending.value) {
      emit("updateIsSending", true);
      isShow.value = true;
      messages.value.push({ sender: "user", content: item.surveyName });
      messages.value.push({ sender: "bot", content: moduleNames });
      emit("updateMessages", { sender: "user", content: item.surveyName });
      emit("updateMessages", { sender: "bot", content: moduleNames });
      nextTick(() => {
        scrollToBottom();
      });
    }
  } else {
    let message = item.content || item.surveyName;
    console.log("isSending:", isSending.value);
    if (!isSending.value) {
      emit("updateIsSending", true);
      isShow.value = true;
      try {
        const name = message;
        const response = await genRecords({ userId: userId.value, name });
        currentConversationId.value = response.data.data.id;
        historyItems.value.push({
          id: response.data.data.id,
          name: response.data.data.name,
        });
        console.log("创建新对话成功:", response);
        await getChatList();
      } catch (error) {
        console.error("新建对话失败:", error);
        return;
      }
      sharedState.refresh = !sharedState.refresh;
      messages.value.push({ sender: "user", content: message });
      emit("updateMessages", { sender: "user", content: messages.value });
      console.log("消息推送成功");
      openEventSourceConnection(message, currentConversationId.value);
      userInput.value = "";
      scrollToBottom();
    }
  }
};
// 进入聊天页面查询对话列表
const getChatList = async () => {
  const data = {
    userId: userId.value,
  };
  const res = await getRecords(data);
  if (res.data && Array.isArray(res.data.data)) {
    historyItems.value = res.data.data.slice(0, 30);
    emit("upHistoryItems", historyItems.value);
  } else {
    console.log("对话列表数据不可用或不是一个数组");
    historyItems.value = [];
    emit("upHistoryItems", historyItems.value);
  }

  if (res.code === 200) {
    messages.value = res.data;
  }
};
//重置问卷
const resetWj = async (item) => {
  console.log("重置问卷:", item);
  try {
    const response = await deleteUserAnswer({
      surveyId: item.id,
      userId: userId.value,
    });
    if (response.data.code === 0) {
      messages.value = [];
      console.log("问卷重置成功", response);
      ElMessage.success("问卷重置成功");
      await getSurveys();
    }
  } catch (error) {
    console.error("问卷重置失败", error);
  }
};
// 获取问卷列表
const getSurveysList = async () => {
  const data = {
    creatorCompanyId: companyId.value,
    pageNo: 1,
    pageSize: 10,
  };
  try {
    const response = await getSurveys(data);
    SurveysList.value = response.data.data.list;
  } catch (e) {
    console.error("请求数据失败:", e);
  }
};
//根据问卷id获得问卷模块分页
const getModules = async (id) => {
  console.log("id", id);
  const data = {
    surveyId: id,
    pageNo: 1,
    pageSize: 20,
    userId: userId.value,
  };
  try {
    const response = await getModulesBySurveyId(data);
    console.log("response", response.data.data);
    modulesList.value = response.data.data.list;
    moduleNames.value = modulesList.value.map((item) => ({
      moduleName: item.moduleName,
      moduleId: item.id,
      unfinishedQuantity: item.unfinishedQuantity,
    }));
    emit("getModuleNames", moduleNames.value);
  } catch (e) {
    console.error("请求数据失败:", e);
  }
};
//获取模块的信息
const getModuleList = async () => {
  try {
    console.log(enableModules.value);
    if (enableModules.value == "1") {
      const response = await getModule({ companyId: companyId.value });
      console.log(response.data.data.list);
      const list = response.data.data.list;
      console.log(list);
      options.value = [{}];
      options.value = list.map((item) => ({
        value: item.moduleId,
        label: item.name,
      }));
      // 如果需要默认选择第一个选项
      if (options.value.length > 0) {
        value.value = options.value[0].value;
        emit("getModuleData", value.value);
        // fetchContentList()
        getFetchContentList();
      }
    }
  } catch (error) {
    console.error("请求模块数据失败:", error);
  }
};
const handleSelectChange = (newValue) => {
  pageNo.value = 0;
  pageSize.value = 6;
  value.value = newValue;
  // this.$emit('send-data', value.value);
  emit("getModuleData", value.value);
  if (type.value === 1) {
    // fetchContentList();
    getFetchContentList();
  }
  // 在这里执行你想要的操作
};
//修改对话模板
const getFetchContentList = () => {
  if (!value.value) {
    value.value = options.value[0].value;
  }
  // 提取和 value 匹配的数据
  contentList.value = contentData.value.filter(
    (item) => item.moduleId == value.value
  );
};
onMounted(async () => {
  companyId.value = route.query.companyId;
  // await fetchCompanyInfo();
  await getSurveysList();
  await fetchContentList();
  await getModuleList();
});

onBeforeUpdate(async () => {
  console.log("公司信息");
  console.log("Company Info on Mounted:", props.companyInfo);
  enableModules.value=props.companyInfo.enableModules;
});

// 跳转页码
const lastPage = () => {
  if(pageNo.value - 6 >= 0) {
    pageNo.value -= 6;
    pageSize.value -= 6;
  }
};

const nextPage = () => {
  if(pageNo.value + 6 <= contentList.value.length){
    pageNo.value += 6;
    pageSize.value += 6;
  }
};
</script>
  <style lang="scss" scoped>
</style>