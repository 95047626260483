import request from "@/utils/request";

// 进入聊天页面查询对话列表
export function getRecords(data) {
  return request({
    url: "/admin-api/model/conversations/getRecords",
    method: "get",
    params: data,
  });
}
// 新建对话接口
export function genRecords(param) {
  return request({
    url: "/admin-api/model/conversations/genRecords",
    method: "post",
    data: param,
  });
}
// 重命名对话接口
export function updateName(param) {
    return request({
      url: "/admin-api/model/conversations/updateName",
      method: "put",
      data: param,
    });
}
  // 获取对话历史接口
export function getMessage(data) {
    return request({
      url: "/admin-api/model/conversations/getMessage",
      method: "get",
      params: data,
    });
  }
// 清除单个对话
export function clearById(id) {
  return request({
    url: `/admin-api/model/conversations/clearById?conversationId=` + id,
    method: "delete",
  });
}

// 清除全部对话
export function clearAll(id) {
  return request({
    url: `/admin-api/model/conversations/clearAll?userId=` + id,
    method: "delete",
  });
}
