import request from "@/utils/request";

//文字生成图片接口,返回taskId
export function imageGen(data) {
  return request({
    url: "/app-api/model/chat/imageGen",
    method: "get",
    params: data,
  });
}

//查询图片生成结果
export function checkImageGeneration(data) {
  return request({
    url: "/app-api/model/chat/checkImageGeneration",
    method: "get",
    params: data,
  });
}