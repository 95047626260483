<template>
  <div class="login">
    <el-alert
      v-if="showAlert"
      :title="alertMessage"
      :type="alertType"
      center
      show-icon
      class="alert-above-dialog"
    >
    </el-alert>
    <div class="login-box">
      <div class="login-box-content">
        <h1>用户登录</h1>
        <el-form
          ref="formRef"
          :model="loginForm"
          :rules="loginRules"
          class="demo-ruleForm login-container"
          @keyup.enter="handleLogin"
        >
          <el-form-item prop="userName">
            <el-input
              v-model="loginForm.userName"
              type="text"
              auto-complete="off"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              type="password"
              auto-complete="off"
              placeholder="密码"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="rememberMe">记住密码</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button class="btn" type="primary" @click="handleLogin"
              >登录</el-button
            >
            <!-- <el-button class="btnRegister" type="primary" @click="toggleDialog"
              >注册</el-button
            > -->
            <div class="register-div">
              还没有账号？<span
                @click="toggleDialog"
                style="color: #012ec7; cursor: pointer"
                >立即注册</span
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- App样式 -->
      <div class="App-login-box">
        <img src="@/assets/img/login_txt.png" alt="" width="150" />
        <p>AI智能客服机器人</p>
        <img src="@/assets/img/icon.png" alt="" class="icon-bg" />
        <div class="App-login-content">
          <el-form
            ref="formRef"
            :model="loginForm"
            :rules="loginRules"
            class="demo-ruleForm login-container"
          >
            <el-form-item prop="userName" class="mb-2">
              <div class="lable-txt">用户名</div>
              <el-input
                v-model="loginForm.userName"
                type="text"
                auto-complete="off"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password" class="mb-2">
              <div class="lable-txt">密码</div>
              <el-input
                v-model="loginForm.password"
                type="password"
                auto-complete="off"
                placeholder="请输入密码"
              >
              </el-input>
            </el-form-item>
            <div class="register-box" @click="toggleDialog">
              <p>新用户注册</p>
            </div>
          </el-form>
        </div>
        <div class="btn-div">
          <el-button class="App-btn" type="primary" @click="handleLogin"
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
  <!-- 模态框 -->
  <el-dialog v-model="dialogVisible" title="注册账号" width="600">
    <el-form
      label-position="top"
      ref="registerFormRef"
      :model="registerForm"
      :rules="registerRules"
      label-width="100px"
      class="registerForm"
    >
      <el-form-item label="手机号码" prop="contact">
        <el-input
          v-model="registerForm.contact"
          placeholder="请输入手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="registerForm.password"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="password2">
        <el-input
          type="password"
          v-model="registerForm.password2"
          placeholder="请再次输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="公司名称" prop="companyName">
        <el-input
          type="text"
          v-model="registerForm.companyName"
          placeholder="请输入公司名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="userName">
        <el-input
          type="text"
          v-model="registerForm.userName"
          placeholder="请输入您的用户名"
        ></el-input>
      </el-form-item>
      <el-button class="register-button" type="primary" @click="handleRegister"
        >注册</el-button
      >
    </el-form>
  </el-dialog>
</template>
  
<script>
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { login, registerApi } from "@/api/loginApi";
import "@/assets/css/LoginStyle.css";
import store from "../../store/store";

export default {
  name: "UserLogin",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loginForm = ref({
      contact: "",
      password: "",
    });
    const registerForm = ref({
      contact: "",
      password: "",
      password2: "",
      companyName: "",
    });
    const showAlert = ref(false);
    const dialogVisible = ref(false);
    const alertMessage = ref("");
    const alertType = ref("success");
    const rememberMe = ref(false);

    // 校验登录
    const loginRules = ref({
      userName: [{ required: true, message: "请输入用户名", trigger: "blur" }],
      password: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { min: 6, message: "密码长度不能少于6位", trigger: "blur" },
      ],
    });

    // 校验注册
    const registerRules = ref({
      contact: [
        { required: true, message: "请输入手机号码", trigger: "blur" },
        {
          pattern:
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: "手机号码格式不正确",
          trigger: "blur",
        },
      ],
      userName: [{ required: true, message: "请输入用户名", trigger: "blur" }],
      password: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { min: 6, message: "密码长度不能少于6位", trigger: "blur" },
      ],
      password2: [
        { required: true, message: "请再次输入密码", trigger: "blur" },
        { min: 6, message: "密码长度不能少于6位", trigger: "blur" },
        {
          validator: (rule, value, callback) => {
            if (value !== registerForm.value.password) {
              callback(new Error("两次输入的密码不一致"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    });

    // 观察 dialogVisible 的变化
    watch(dialogVisible, (newValue) => {
      if (!newValue) {
        // 重置 registerForm
        registerForm.value = {
          contact: "",
          password: "",
          password2: "",
          companyName: "",
        };
      }
    });
    // 在组件挂载时检查
    onMounted(() => {
      const savedLoginForm = localStorage.getItem("loginForm");
      if (savedLoginForm) {
        const parsedLoginForm = JSON.parse(savedLoginForm);
        loginForm.value = { ...parsedLoginForm };
        rememberMe.value = true;
      }

      const isAuthenticated = localStorage.getItem("isAuthenticated");
      if (isAuthenticated === "true") {
        router.push({ name: "IndexPage" });
      }
    });

    const toggleDialog = () => {
      dialogVisible.value = !dialogVisible.value;
    };
    // 登录功能
    const formRef = ref(null);
    const handleLogin = async () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          try {
            const response = await login(loginForm.value);
            if (response.data.code === 1002000000) {
              console.error(response.msg);
              alertMessage.value = response.data.msg;
              alertType.value = "error";
              showAlert.value = true;
              setTimeout(() => {
                showAlert.value = false;
              }, 3000);
              return;
            }

            const { data } = response;
            localStorage.setItem("userInfo", JSON.stringify(data));
            store.commit("SET_USER_INFO", data);
            if (rememberMe.value) {
              localStorage.setItem(
                "loginForm",
                JSON.stringify(loginForm.value)
              );
            } else {
              localStorage.removeItem("loginForm");
            }
            localStorage.setItem("isAuthenticated", "true");
            const companyId = route.query.companyId || "1753588042175647745";
            router.push({ name: "IndexPage", query: { companyId } });
          } catch (error) {
            console.error("登录失败", error);
            alertMessage.value = "登录失败，请检查输入或稍后重试。";
            alertType.value = "error";
            showAlert.value = true;
            setTimeout(() => {
              showAlert.value = false;
            }, 3000);
          }
        } else {
          console.log("验证失败");
        }
      });
    };

    // 注册功能
    const registerFormRef = ref(null);
    const handleRegister = () => {
      registerFormRef.value.validate(async (valid) => {
        if (valid) {
          const formData = { ...registerForm.value };
          delete formData.password2;
          try {
            const response = await registerApi(formData);
            console.log("注册成功", response);
            alertMessage.value = "注册成功";
            alertType.value = "success";
            showAlert.value = true;
            setTimeout(() => {
              showAlert.value = false;
              dialogVisible.value = false;
            }, 3000);
          } catch (error) {
            console.error("注册失败", error);
            alertMessage.value = "注册失败，请检查输入或稍后重试。";
            alertType.value = "error";
            showAlert.value = true;
            setTimeout(() => {
              showAlert.value = false;
            }, 3000);
          }
        }
      });
    };

    return {
      loginForm,
      loginRules,
      handleLogin,
      formRef,
      dialogVisible,
      toggleDialog,
      registerForm,
      registerRules,
      handleRegister,
      registerFormRef,
      showAlert,
      alertMessage,
      alertType,
      rememberMe,
    };
  },
};
</script>
<style>
</style>
