<template>
  <div class="left-nav">
    <div class="logo-title" @click="toggleDialog">
      <img src="@/assets/img/jqr.png" alt="" width="60" />
      <p :style="{ color: color }">智能客服机器人</p>
    </div>
    <div class="search-box">
      <span :style="{ borderLeftColor: color }">
        <i class="iconfont icon-search" :style="{ color: color }"></i>
      </span>
      <input type="text" v-model="searchQuery" placeholder="搜索历史记录" />
    </div>
    <div class="new-chat" @click="createNewConversation">
      <div style="display: flex; align-items: center; padding-left: 20px">
        <i class="iconfont icon-jia" :style="{ color: color }"></i>
        <p :style="{ color: color }" style="margin-left: 10px">新建对话</p>
      </div>
      <i class="iconfont icon-youbian"></i>
    </div>
    <el-divider></el-divider>
    <div class="history-list">
      <div class="history-title">
        <span>历史记录</span>
        <el-popconfirm
          confirm-button-text="好的"
          cancel-button-text="不用了"
          title="将清空历史记录，是否继续删除？"
          @confirm="clearHistory"
        >
          <template #reference>
            <el-button>清除</el-button>
          </template>
        </el-popconfirm>
      </div>
      <div class="history-content">
        <div
          class="history-item"
          v-for="(item, index) in filteredHistory"
          :key="index"
          @mouseenter="
            showDelete = index;
            showRename = index;
          "
          @mouseleave="
            showDelete = null;
            showRename = null;
          "
          @click="selectConversation(item.id)"
        >
          <template v-if="editingIndex !== index">
            <div class="item-name">
              {{ item.name }}
            </div>
            <div class="icons">
              <div
                v-if="showRename === index"
                class="rename-btn"
                @click.stop="enableEditing(index, item)"
              >
                <i class="iconfont icon-zhongmingming"></i>
              </div>
              <div
                v-if="showDelete === index"
                class="delete-btn"
                @click.stop="deleteHistoryItem(item.id)"
              >
                <i class="iconfont icon-shanchu"></i>
              </div>
            </div>
          </template>
          <template v-else>
            <input
              v-model="editingName"
              class="rename-input"
              @keyup.enter="renameHistoryItem(item.id, editingName)"
              @blur="tryRename(item.id, editingName)"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="history-footer" @click="logout">
      <div>
        <i class="iconfont icon-tuichudenglu"></i>
        <span>退出登录</span>
      </div>
      <i class="iconfont icon-youbian"></i>
    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
import { defineProps, ref, computed, inject, onMounted, watch } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router"; //获取路由实例
import {
  genRecords,
  clearAll,
  getRecords,
  getMessage,
  updateName,
  clearById,
} from "@/api/recordsApi";

const emit = defineEmits([
  "updateVisibility",
  "toggle-dialog",
  "upMessages",
  "upIsChatContentVisible",
]);
const searchQuery = ref(""); //用户输入搜索内容
const historyItems = ref([]); //历史记录数组
const currentConversationId = ref(null); //当前选中的对话id
const userInput = ref(""); //用户输入
const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
const userId = ref(userInfo.data.userId); //用户id
const messages = ref([]); //对话消息数组
const isChatContentVisible = ref(true); //是否显示对话内容
const isSending = ref(false); //是否正在发送消息
const loading = ref(true); //是否正在加载
const showDelete = ref(null); //是否显示删除按钮
const showRename = ref(null); //是否显示重命名按钮
const editingIndex = ref(-1); //当前正在编辑的索引
const editingName = ref(""); //当前正在编辑的名称
const router = useRouter(); // 获取路由实例
const dialogVisible = ref(false); //对话框是否可见
const sharedState = inject("sharedState"); //注入共享状态
const props = defineProps({
  isProcess: Boolean,
  color: String,
  anyMessage: Number,
  historyItems: {
    type: Array,
    default: () => [],
  },
});
// 创建新对话
const createNewConversation = async () => {
  console.log('isProcess', props.isProcess);
  if (props.isProcess) {
    ElMessage.warning("刷新页面即可重新发起对话");
    return;
  }
  if (currentConversationId.value &&(props.anyMessage <= 0 &&  messages.value.length === 0 )) {
    ElMessage.warning("请在当前对话中发送消息后再创建新的对话。");
    return;
  }
  try {
    const name = userInput.value.trim() || "新建对话";
    const response = await genRecords({ userId: userId.value, name });
    currentConversationId.value = response.data.data.id;
    historyItems.value.push({ name });
    await getChatList();
    await selectConversation(currentConversationId.value);
    emit("updateVisibility", true);
  } catch (error) {
    console.error("新建对话失败:", error);
  }
};
// 清空历史记录数组
const clearHistory = async () => {
  try {
    await clearAll(userId.value);
    historyItems.value = [];
    localStorage.setItem("historyCleared", "true");
    window.location.reload();
  } catch (error) {
    console.error(`清空历史记录失败: ${error}`);
  }
};
// 过滤历史记录
const filteredHistory = computed(() => {
  return historyItems.value.filter((item) =>
    item.name?.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});
// 进入聊天页面查询对话列表
const getChatList = async () => {
  const data = {
    userId: userId.value,
  };
  const res = await getRecords(data);
  if (res.data && Array.isArray(res.data.data)) {
    console.log("对话列表:", res.data);
    historyItems.value = res.data.data.slice(0, 30);
  } else {
    console.log("对话列表数据不可用或不是一个数组");
    historyItems.value = [];
  }

  if (res.code === 200) {
    messages.value = res.data;
  }
};
// 获取对话历史接口的实现
const selectConversation = async (
  conversationId,
  pageNo = 1,
  pageSize = 10
) => {
  if (props.isProcess) {
    ElMessage.warning("刷新页面即可重新发起对话");
    return;
  }
  currentConversationId.value = conversationId;
  try {
    const response = await getMessage({ conversationId, pageNo, pageSize });
    const messagesList = response.data.data.list.map((msg) => ({
      sender: msg.senderType === 1 ? "user" : "bot",
      content: msg.content,
    }));
    messages.value = messagesList;
    emit("upMessages", messages.value);
    isChatContentVisible.value = response.data.data.list.length < 1;
    emit("upIsChatContentVisible", isChatContentVisible.value);
    isSending.value = false;
    scrollToBottom();
  } catch (error) {
    console.error("获取对话历史失败:", error);
  } finally {
    loading.value = false;
  }
};
// 重命名历史记录项
const renameHistoryItem = async (id, newName) => {
  try {
    await updateName({ id, name: newName.trim() });
    const item = historyItems.value.find((item) => item.id === id);
    if (item) {
      item.name = newName.trim();
    }
  } catch (error) {
    console.error("重命名失败:", error);
  } finally {
    editingIndex.value = -1;
    editingName.value = "";
  }
};
// 启用编辑模式
const enableEditing = (index, item) => {
  editingIndex.value = index;
  editingName.value = item.name;
};

const tryRename = async (id, newName) => {
  if (
    !newName.trim() ||
    newName === historyItems.value[editingIndex.value]?.name
  ) {
    editingIndex.value = -1;
    editingName.value = "";
    return;
  }
  await renameHistoryItem(id, newName);
};
// 删除某条历史记录
const deleteHistoryItem = async (id) => {
  try {
    console.log("删除对话id:", id);
    await clearById(id);
    historyItems.value = historyItems.value.filter((item) => item.id !== id);
    await getChatList();
    window.location.reload();
  } catch (error) {
    console.error(`删除对话失败: ${error}`);
  }
};
//退出登录
const logout = () => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("isAuthenticated");
  router.push("/");
};
//显示弹窗公司基本信息
const toggleDialog = () => {
  dialogVisible.value = !dialogVisible.value;
  emit("toggle-dialog", dialogVisible.value);
};
// 定义一个异步函数来获取聊天列表
const fetchData = async () => {
  await getChatList();
};

onMounted(async () => {
  await fetchData();
  watch(
    () => sharedState.refresh,
    (newVal) => {
      if (newVal) {
        getChatList();
      }
    },
    { immediate: true }
  );
});
const scrollToBottom = inject("scrollToBottom");
</script>
<style scoped>
</style>