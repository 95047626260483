import request from "@/utils/request";

//上传图片接口
export function upload(formData) {
  return request({
    url: "/app-api/infra/file/upload",
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

//AI理解图片接口
export function ImageUnderstanding(data) {
  return request({
    url: "/app-api/model/chat/ImageUnderstanding",
    method: "get",
    params: data,
  });
}
